export const MODAL = {
  REGISTER: 'modalRegister',
  LOGIN: 'modalLogin',
  FORGOT: 'modalForgot',
  RESET_PASS: 'modalResetPassword',
  AUTHENTICATION: 'modalAuthentication',
  MATCH_DETAIL: 'modalMatchDetail',
  SCHEDULE_FILTER: 'modalScheduleFilter',
  UPDATE_AMOUNT: 'modalUpdateAmount',
  CANCEL_CRYPTO: 'modalCancelCrypto',
  MODAL_ADD_FIRST_BANK: 'modal-add-first-bank',
  MODAL_VERIFY_PHONE: 'modalVerifyPhone',
  MODAL_CONFIRM: 'modalConfirm',
  MODAL_DATE_RANGER: 'modalDateRanger',
  MODAL_HISTORY_FILTER: 'modalHistoryFilter',
  DISPLAY_NAME: 'modalDisplayName',
  CHANGE_AVATAR: 'modalChangeAvatar',
  FORGOT_RESET: 'modalForgotReset',
  TRANSACTION_HISTORY: 'TRANSACTION_HISTORY',
  TRANSACTION_HISTORY_MB: 'TRANSACTION_HISTORY_MB',
  BET_HISTORY: 'BET_HISTORY',
  BET_HISTORY_MB: 'BET_HISTORY_MB',
  CANCEL_PROMOTION: 'modalCancelPromotion',
  REQUIRE_DEPOSIT: 'modalRequireDeposit',
  BLOCK_GAME: 'modalBlockGame'
}

export const PROMOTION_PLAN_TYPE = {
  COMMISSION: 1,
  WELCOME: 2, // 150%
  WELCOME2: 3, // 100%.
  WELCOME3: 7, // 50%
  PROMOTION: 5, // 80%
  PROMOTION50K: 6 // 50k
}

export const DEFAULT_GAME = {
  ingame: 'lobby-ingame',
  cardGame: 'lobby-game-bai',
  slots: 'lobby-slots',
  lotte: 'lotte',
  fishing: 'lobby-ban-ca',
  quickgame: 'lobby-quick-game',
  table: 'lobby-table-game',
  instant: 'lobby-toc-chien',
  nohu: 'lobby-nohu',
  keno: 'keno',
  cockfight: 'cockfight'
}
